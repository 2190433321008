<template>
  <div>
    <b-tabs
      v-if="activeTab !== null"
      v-model="activeTab"
      :fill="fill"
      :justified="justified"
      :align="align"
      :pills="pills"
      nav-class="gap-0.34"
      @activate-tab="$emit('change', $event); changeUrl(showTabs.filter(({ show }) => show)[$event].key, $event)"
      @input="t"
    >
      <template v-for="(tab, i) in showTabs.filter(({ show }) => show)">
        <b-tab
          :key="i"
          :title="$t(tab.title)"
          :lazy="tab.lazy || lazy"
          :disabled="tab.disabled"
          :title-link-class="
            pills
              ? activeTab !== null && showTabs.filter(({ show }) => show)[activeTab] && showTabs.filter(({ show }) => show)[activeTab].key === tab.key
                ? 'btn btn-primary btn-sm font-small-2 px-75 py-50'
                : 'btn btn-outline-primary btn-sm font-small-2 px-75 py-50'
              : ''"
          class="position-relative"
        >
          <template #title>
            <sw-icon
              :icon="tab.icon"
              :class="{
                'mr-n25': pills,
                'mt-n25': !tab.icon.startsWith('hi-')
              }"
              :size="pills ? '12' : ''"
              :scale="pills ? '0.6' : ''"
            />

            <span>{{ $t(tab.title) }}</span>

            <b-spinner
              v-if="tab.loading"
              type="border"
              small
              class="ml-50"
            />
          </template>

          <component
            :is="card ? 'card' : 'div'"
            v-if="tab.component"
            class="h-100"
            style="overflow-x: hidden"
            :no-body="noBody"
          >
            <component
              :is="tab.component"
              v-bind="{ thread, ...(tab.meta.componentProps || {}) }"
            />
          </component>

          <h4
            v-else
            class="py-2 text-center text-primary"
          >
            {{ $t('NoData') }} 😔
          </h4>
        </b-tab>
      </template>

      <template #tabs-end>
        <b-nav-item
          id="addView"
          ref="button"
          role="presentation"
          :link-classes="pills && 'btn btn-flat-primary btn-sm font-small-2 px-75 py-50'"
        >
          <sw-icon
            icon="PlusIcon"
            class="mt-n25"
            :class="pills && 'mr-n25'"
            :size="pills && '12rem'"
          />

          <span>{{ $t('View') }}</span>
        </b-nav-item>
      </template>

      <template #empty>
        <h4 class="text-center text-secondary font-weight-normal">
          {{ $t('NoTabs') }}
        </h4>
      </template>
    </b-tabs>

    <b-popover
      target="addView"
      triggers="focus"
      placement="bottom"
      variant="flat-primary"
      custom-class="max-w-max"
    >
      <b-row
        style="width: 40rem; max-width: 84vw; max-height: 60vh; row-gap: .1rem;"
        class="overflow-auto"
      >
        <b-col
          v-for="(tab, j) in showTabs"
          :key="j"
          cols="12"
          md="6"
          class="d-flex"
        >
          <b-button
            :variant="tab.show ? 'flat-primary' : 'flat-secondary'"
            class="d-flex flex-row align-items-center px-75 py-1 flex-grow-1"
            style="column-gap: .84rem"
            @click="$emit('change-show', { tab: tab, value: !tab.show })"
          >
            <b-avatar
              :variant="tab.show ? 'light-primary' : 'light-secondary'"
              rounded="sm"
            >
              <sw-icon :icon="tab.icon" />
            </b-avatar>

            <div
              class="d-flex flex-column align-items-start text-nowrap"
              style="row-gap: .34rem"
            >
              <span>
                {{ $t(tab.title) }}
              </span>

              <small class="text-secondary">
                {{ $t(tab.description) }}
              </small>
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-popover>
  </div>
</template>

<script>

import { BNavItem, BPopover } from 'bootstrap-vue'
import router from '@/router'

export default {
  components: {
    BNavItem,
    BPopover,
  },
  props: {
    tabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    thread: {
      required: false,
      default: null,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
    fill: {
      type: Boolean,
      required: false,
      default: false,
    },
    justified: {
      type: Boolean,
      required: false,
      default: false,
    },
    pills: {
      type: Boolean,
      required: false,
      default: false,
    },
    align: {
      type: String,
      required: false,
      default: 'start',
    },
    card: {
      type: Boolean,
      required: false,
      default: false,
    },
    noBody: {
      type: Boolean,
      required: false,
      default: false,
    },
    url: {
      type: Boolean,
      required: false,
      default: false,
    },
    baseUrl: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['change', 'change-show'],
  data: () => ({
    activeTab: null,
    showTabs: [],
  }),
  watch: {
    '$route.params.tab': {
      deep: true,
      handler(nw) {
        this.$set(this, 'activeTab', this.tabs.filter(({ show }) => show).findIndex(e => e.key === nw))
      },
    },
    tabs: {
      deep: true,
      async handler(n) {
        this.showTabs = n

        if (this.url) {
          const tab = String(router.currentRoute.params?.tab)

          console.log(tab, 'tabs watch')
          // eslint-disable-next-line eqeqeq
          if (tab != undefined) {
            this.$set(this, 'activeTab', this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab))
          }
          // this.activeTab = this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab)

          // if (!tab) await this.changeUrl(tab)
        }
      },
    },
  },
  async mounted() {
    this.showTabs = this.tabs

    if (this.url) {
      const tab = String(router.currentRoute.params?.tab)

      console.log(tab, 'MOUNTED')
      this.$set(this, 'activeTab', this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab))
      // this.activeTab = this.tabs.filter(({ show }) => show).findIndex(e => e.key === tab)
      if (!tab) await this.changeUrl(tab)
    }
  },
  methods: {
    t(e, l, a) {
      console.log(e, l, a)
    },
    async changeUrl(i, log) {
      console.log(i, 'changeUrl', log)
      // if (this.url) await this.$router.push(this.baseUrl ? { path: `${this.baseUrl}/tab/${i}`, replace: true } : { query: { tab: i }, replace: true })
      const targetPath = this.baseUrl ? `${this.baseUrl}/${i}` : null
      const targetQuery = this.baseUrl ? null : { tab: i }

      // Get current path and query
      const currentPath = this.$route.path
      const currentQuery = this.$route.query

      // Check if the path or query is different before navigating
      if (this.thread) {
        if ((targetPath && (currentPath !== targetPath)) || (targetQuery && currentQuery.tab !== i.toString())) {
          if (targetPath) {
            await this.$router.push({ path: targetPath, replace: true })
          } else {
            await this.$router.push({ query: targetQuery, replace: true })
          }
        }
      }
    },
  },
}
</script>
